import { createContext, useState, useContext, useEffect } from "react";

const UserDataContext = createContext();

export function useUserData() {
  return useContext(UserDataContext);
}

export function UserDataProvider({ children }) {
  const [userData, setUserData] = useState(() => {
    const storedData = localStorage.getItem("userData");
    return storedData ? JSON.parse(storedData) : null;
  });

  // Function to update user data
  const updateUser = (data) => {
    // Merge the new data with the existing user data using spread syntax
    const updatedUserData = { ...userData, ...data };
    setUserData(updatedUserData);
    localStorage.setItem("userData", JSON.stringify(updatedUserData));
  };

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userData));
  }, [userData]);

  return (
    <UserDataContext.Provider value={{ userData, updateUser }}>
      {children}
    </UserDataContext.Provider>
  );
}
