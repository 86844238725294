import {Helmet} from "react-helmet";
import DashNavbar from '../../components/DashNavbar'
import Stats from '../../components/Stats'

function DashboardPage() {
    return (
        <div>
            <Stats/>
        </div>
    );
}

function Dashboard() {
    return (
        <div>
            <Helmet>
                <title>Safevoy | Home</title>
            </Helmet>

            <div>
                <DashNavbar
                    PageName='Dashboard'
                    PageContent={DashboardPage}
                />
            </div>
        </div>
    );
}

export default Dashboard;
