import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition, Disclosure } from '@headlessui/react'
import {
    Bars3Icon,
    ShoppingBagIcon,
    Cog6ToothIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useUserData } from "../context/UserDataContext"; // Import the user data context
import { getAuth, signOut } from "firebase/auth";


import logo from '../images/green_logo.png'

import { Link } from 'react-router-dom';

const navigation = [
    { name: 'Home', href: '/home', icon: HomeIcon, current: false },
    {   name: 'Static ISP Proxies', 
        icon: UsersIcon, 
        current: false,
        href: '/isp-static'
    },
    {   name: 'Static Datacenter Proxies', 
        icon: UsersIcon, 
        current: false,
        href: '/isp-static'
},
    { name: 'Top Up', href: '/top-up', icon: ShoppingBagIcon, current: false },
]

const handleSignOut = () => {
    const auth = getAuth(); // Initialize the auth object
    signOut(auth)
        .then(() => {
            console.log("Signed out")
        })
        .catch((error) => {
            // Handle sign-out error if needed
            console.error("Sign-out error:", error);
        });
};

// const navigation = [
//     { name: 'Home', href: '/home', icon: HomeIcon, current: false },
//     {   name: 'ISP Proxies', 
//         icon: UsersIcon, 
//         current: false,
//         children: [
//             { name: 'Rotating ISPs', href: '/isp-static' },
//             { name: 'Static ISPs', href: '/isp-static' },
//           ],
//     },
//     {   name: 'Datacenter Proxies', 
//         icon: UsersIcon, 
//         current: false,
//         children: [
//         { name: 'Rotating DCs', href: '/isp-static' },
//         { name: 'Static DCs', href: '/isp-static' },
//       ],
// },
//     { name: 'Top Up', href: '/top-up', icon: ShoppingBagIcon, current: false },
// ]

const userNavigation = [
    { name: 'Your profile', href: '#' },
    { name: 'Sign out', href: '#', onClick: handleSignOut },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DashNavbar({PageContent, PageName}) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const { userData, updateUser } = useUserData();

    // console.log(userData.photoURL)


    return (
        <>
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    {/* Sidebar component, swap this element with another sidebar if you like */}
                                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                                        <div className="flex h-16 shrink-0 items-center">
                                            <img
                                                className="h-8 w-auto"
                                                src={logo}
                                                alt="Safevoy"
                                            />
                                        </div>
                                        <nav className="flex flex-1 flex-col">
                                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                            <li>
                                                <ul role="list" className="-mx-2 space-y-1">
                                                {navigation.map((item) => (
                                                    <li key={item.name}>
                                                    {!item.children ? (
                                                        <a
                                                        href={item.href}
                                                        className={classNames(
                                                            item.current ? 'bg-gray-50' : 'hover:bg-gray-50 hover:text-safevoy-green',
                                                            'block rounded-md py-2 pr-2 pl-10 text-sm leading-6 font-medium text-gray-700'
                                                        )}
                                                        >
                                                        {item.name}
                                                        </a>
                                                    ) : (
                                                        <Disclosure as="div">
                                                        {({ open }) => (
                                                            <>
                                                            <Disclosure.Button
                                                                className={classNames(
                                                                item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-medium text-gray-700'
                                                                )}
                                                            >
                                                                <ChevronRightIcon
                                                                className={classNames(
                                                                    open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                                                    'h-5 w-5 shrink-0'
                                                                )}
                                                                aria-hidden="true"
                                                                />
                                                                {item.name}
                                                            </Disclosure.Button>
                                                            <Disclosure.Panel as="ul" className="mt-1 px-2">
                                                                {item.children.map((subItem) => (
                                                                <li key={subItem.name}>
                                                                    <Disclosure.Button
                                                                    as="a"
                                                                    href={subItem.href}
                                                                    className={classNames(
                                                                        subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50 hover:text-safevoy-green',
                                                                        'block rounded-md py-2 pr-2 pl-10 text-sm leading-6 font-medium text-gray-700'
                                                                    )}
                                                                    >
                                                                    {subItem.name}
                                                                    </Disclosure.Button>
                                                                </li>
                                                                ))}
                                                            </Disclosure.Panel>
                                                            </>
                                                        )}
                                                        </Disclosure>
                                                    )}
                                                    </li>
                                                ))}
                                                </ul>
                                            </li>
                                            <li className="mt-auto">
                                                    <a
                                                        href="/settings"
                                                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6 text-gray-700 hover:bg-gray-50 hover:text-safevoy-green"
                                                    >
                                                        <Cog6ToothIcon
                                                            className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-safevoy-green"
                                                            aria-hidden="true"
                                                        />
                                                        Settings
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
                        <div className="flex h-16 shrink-0 items-center">
                            <img
                                className="h-8 w-auto"
                                src={logo}
                                alt="Safevoy"
                            />
                        </div>
                        <nav className="flex flex-1 flex-col">
                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul role="list" className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                    <li key={item.name}>
                                    {!item.children ? (
                                        <Link
                                        to={item.href}
                                        className={classNames(
                                            item.current ? 'bg-gray-50' : 'hover:bg-gray-50 hover:text-safevoy-green',
                                            'block rounded-md py-2 pr-2 pl-10 text-sm leading-6 font-medium text-gray-700'
                                        )}
                                        >
                                        {item.name}
                                        </Link>
                                    ) : (
                                        <Disclosure as="div">
                                        {({ open }) => (
                                            <>
                                            <Disclosure.Button
                                                className={classNames(
                                                item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-medium text-gray-700'
                                                )}
                                            >
                                                <ChevronRightIcon
                                                className={classNames(
                                                    open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                                    'h-5 w-5 shrink-0'
                                                )}
                                                aria-hidden="true"
                                                />
                                                {item.name}
                                            </Disclosure.Button>
                                            <Disclosure.Panel as="ul" className="mt-1 px-2">
                                                {item.children.map((subItem) => (
                                                <li key={subItem.name}>
                                                    <Link 
                                                    to={subItem.href}
                                                    className={classNames(
                                                            subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50 hover:text-safevoy-green',
                                                            'block rounded-md py-2 pr-2 pl-10 text-sm leading-6 font-medium text-gray-700'
                                                        )}
                                                    >
                                                        <Disclosure.Button
                                                        >
                                                        {subItem.name}
                                                        </Disclosure.Button>
                                                    </Link>
                                                </li>
                                                ))}
                                            </Disclosure.Panel>
                                            </>
                                        )}
                                        </Disclosure>
                                    )}
                                    </li>
                                ))}
                                </ul>
                            </li>
                            <li className="mt-auto">
                                    <Link
                                        to="/settings"
                                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6 text-gray-700 hover:bg-gray-50 hover:text-safevoy-green"
                                    >
                                        <Cog6ToothIcon
                                            className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-safevoy-green"
                                            aria-hidden="true"
                                        />
                                        Settings
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="lg:pl-72 h-screen">
                    <div className="sticky top-0 z-40 flex h-12 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                        <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        {/* Separator */}
                        <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

                        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                            <div className="relative flex flex-1 items-center">
                                <h2 className='text-md font-medium text-black gilroy'>{PageName}</h2>
                            </div>
                            <div className="flex items-center gap-x-4 lg:gap-x-6">
                                {/* <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button> */}

                                {/* Separator */}
                                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative">
                                    <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                        <span className="sr-only">Open user menu</span>
                                        <img
                                            className="h-7 w-7 rounded-full bg-gray-50"
                                            src={userData?.photoURL}
                                            alt="Profile Picture" 
                                        />
                                        <span className="hidden lg:flex lg:items-center">
                                            <span className="ml-2 text-sm font-medium leading-6 text-gray-900" aria-hidden="true">
                                            {userData?.displayName}                                            </span>
                                            <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                            {userNavigation.map((item) => (
                                                <Menu.Item key={item.name}>
                                                    {({ active }) => (
                                                        <button
                                                            onClick={item.onClick}
                                                            href={item.href}
                                                            className={classNames(
                                                                active ? 'bg-gray-50' : '',
                                                                'block px-3 py-1 text-sm leading-6 text-gray-900 w-full block text-left'
                                                            )}
                                                        >
                                                            {item.name}
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <main className="py-10m alternateBackground customVhHeight pt-10 pb-10">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <PageContent/>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}
