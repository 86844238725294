import {Helmet} from "react-helmet";
import DashNavbar from '../../components/DashNavbar'
import PlanTypes from '../../components/PlanTypes'
import Pricing from '../../components/Pricing'

function TopUpPage() {
    return (
        <div className='lg:flex grow flex-col gap-5'>
            <PlanTypes/>
            <Pricing/>
        </div>
    );
}

function TopUp() {
    return (
        <div>
            <Helmet>
                <title>Safevoy | Top Up</title>
            </Helmet>

            <div>
                <DashNavbar
                    PageName='Top Up'
                    PageContent={TopUpPage}
                />
            </div>
        </div>
    );
}

export default TopUp;
