import { useUserData } from "../context/UserDataContext"; // Import the user data context
import { getAuth, deleteUser } from "firebase/auth";


const secondaryNavigation = [
  { name: 'Account', href: '#', current: true },
//   { name: 'Billing', href: '#', current: false },
]

export default function Example() {
  const { userData, updateUser } = useUserData();


  console.log(userData)
  // console.log(userData.displayName)
  // console.log(userData)
  // console.log(userData)

  const handleDeleteAccount = () => {

  const auth = getAuth();
const user = auth.currentUser;

deleteUser(user).then(() => {
  // User deleted.
}).catch((error) => {
  // An error ocurred
  // ...
});
  }
  
  return (
    <>
      <div>
        <div>
          <main>
            <header>
              <nav className="flex overflow-x-auto ">
                <ul
                  role="list"
                  className="flex min-w-full flex-none gap-x-6 text-sm font-semibold leading-6 text-gray-400"
                >
                  {secondaryNavigation.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className={item.current ? 'text-safevoy-green' : ''}>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </header>

            {/* Settings forms */}
            <div className="divide-y divide-white/5">
              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-black">Personal Information</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    Use a permanent address where you can receive mail.
                  </p>
                </div>

                <form className="md:col-span-2">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="col-span-full flex items-center gap-x-8">
                      <img
                        src={userData?.photoURL}
                        alt=""
                        className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                      />
                      {/* <div>
                        <button
                          type="button"
                          className="rounded-md bg-black/10 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-black/20"
                        >
                          Change avatar
                        </button>
                        <p className="mt-2 text-xs leading-5 text-gray-400">JPG, GIF or PNG. 1MB max.</p>
                      </div> */}
                    </div>

                    <div className="sm:col-span-3">
                      <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-black">
                        First name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          className="bg-black/5 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 appearance-none ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-safevoy-green sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-black">
                        Last name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          className="bg-black/5 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 appearance-none ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-safevoy-green sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-black">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          placeholder={userData?.email} 
                          className="bg-black/5 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 appearance-none ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-safevoy-green sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:text-gray-500 disabled:ring-gray-20"
                          disabled
                        />
                      </div>
                    </div>

                    {/* <div className="col-span-full">
                      <label htmlFor="timezone" className="block text-sm font-medium leading-6 text-black">
                        Timezone
                      </label>
                      <div className="mt-2">
                        <select
                          id="timezone"
                          name="timezone"
                          className="bg-black/5 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 appearance-none ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-safevoy-green sm:text-sm sm:leading-6 [&_*]:text-black"
                        >
                          <option>Pacific Standard Time</option>
                          <option>Eastern Standard Time</option>
                          <option>Greenwich Mean Time</option>
                        </select>
                      </div>
                    </div> */}
                  </div>

                  <div className="mt-8 flex">
                    <button
                      type="submit"
                      className="rounded-md bg-safevoy-green px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-safevoy-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-safevoy-green"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>

              {/* <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-black">Change password</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    Update your password associated with your account.
                  </p>
                </div>

                <form className="md:col-span-2">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="col-span-full">
                      <label htmlFor="current-password" className="block text-sm font-medium leading-6 text-black">
                        Current password
                      </label>
                      <div className="mt-2">
                        <input
                          id="current-password"
                          name="current_password"
                          type="password"
                          autoComplete="current-password"
                          className="bg-black/5 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 appearance-none ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-safevoy-green sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="new-password" className="block text-sm font-medium leading-6 text-black">
                        New password
                      </label>
                      <div className="mt-2">
                        <input
                          id="new-password"
                          name="new_password"
                          type="password"
                          autoComplete="new-password"
                          className="bg-black/5 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 appearance-none ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-safevoy-green sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-black">
                        Confirm password
                      </label>
                      <div className="mt-2">
                        <input
                          id="confirm-password"
                          name="confirm_password"
                          type="password"
                          autoComplete="new-password"
                          className="bg-black/5 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 appearance-none ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-safevoy-green sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 flex">
                    <button
                      type="submit"
                      className="rounded-md bg-safevoy-green px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-safevoy-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-safevoy-green"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div> */}

              {/* <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-black">Delete account</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    No longer want to use our service? You can delete your account here. This action is not reversible.
                    All information related to this account will be deleted permanently.
                  </p>
                </div>

                <form className="flex items-start md:col-span-2">
                  <button
                    onClick={handleDeleteAccount}
                    type="submit"
                    className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600"
                  >
                    Yes, delete my account
                  </button>
                </form>
              </div> */}
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
