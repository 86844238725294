import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'

const ProxyTypes = [
  { id: 1, title: 'Static ISP Proxies', description: 'Last message sent an hour ago', users: '621 users' },
  { id: 2, title: 'Rotating ISP Proxies', description: 'Last message sent 2 weeks ago', users: '1200 users' },
  { id: 3, title: 'Dedicated DC Proxies', description: 'Last message sent 4 days ago', users: '2740 users' },
  { id: 4, title: 'Rotating DC Proxies', description: 'Last message sent 4 days ago', users: '2740 users' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function PlanTypes() {
  const [selectedProxyTypes, setSelectedProxyTypes] = useState(ProxyTypes[0])

  return (
    <RadioGroup value={selectedProxyTypes} onChange={setSelectedProxyTypes}>
      <RadioGroup.Label className="text-base font-medium leading-6 text-gray-900">
        Select proxy type
      </RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 md:grid-cols-4 md:gap-x-4">
        {ProxyTypes.map((mailingList) => (
          <RadioGroup.Option
            key={mailingList.id}
            value={mailingList}
            className={({ active }) =>
              classNames(
                active ? 'border-safevoy-green ring-2 ring-safevoy-green' : 'border-gray-300',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {mailingList.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                      {mailingList.description}
                    </RadioGroup.Description>
                    <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                      {mailingList.users}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-safevoy-green')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-safevoy-green' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}