import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCEhbrY97lhJcf_ZU_nZ2lX9yrrK1Ee5_Q",
  authDomain: "safevoy-d89aa.firebaseapp.com",
  projectId: "safevoy-d89aa",
  storageBucket: "safevoy-d89aa.appspot.com",
  messagingSenderId: "454182658678",
  appId: "1:454182658678:web:1037d9e61127fd3cf7b557",
  measurementId: "G-8PQ0Q1LDFH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);