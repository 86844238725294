import {Helmet} from "react-helmet";
import DashNavbar from '../../components/DashNavbar'
// import Stats from '../../components/DashboardComponents/Stats'

function IspProxiesPage() {
    return (
        <div>
            <div>
            <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                Proxy List
            </label>
            <div className="mt-2">
                <textarea
                rows={4}
                name="comment"
                id="comment"
                disabled
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-safevoy-green sm:text-sm sm:leading-6 disabled:text-gray-500 disabled:ring-gray-20 bg-white"
                defaultValue={'0.0.0.000.000:tom:cook'}
                />
            </div>
            </div>
        </div>
    );
}

function IspProxies() {
    return (
        <div>
            <Helmet>
                <title>Safevoy | Static ISPs</title>
            </Helmet>

            <div>
                <DashNavbar
                    PageName='ISP Proxies'
                    PageContent={IspProxiesPage}
                />
            </div>
        </div>
    );
}

export default IspProxies;
