import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/Dashboard/Home";
import Settings from "./pages/Dashboard/Settings";
import StaticISP from "./pages/Dashboard/StaticISP";
import TopUp from "./pages/Dashboard/TopUp";

import { UserDataProvider } from "./context/UserDataContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function App() {
  const auth = getAuth();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  return (
    <Router>
      <UserDataProvider>
        <Routes>
          {user ? (
            <>
              <Route path="/home" element={<Home />} />
              <Route path="/isp-static" element={<StaticISP />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/top-up" element={<TopUp />} />
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/login" element={<Navigate to="/home" />} />
            </>
          ) : (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
      </UserDataProvider>
    </Router>
  );
}

export default App;
