import { useState, useEffect } from 'react'
// import Navbar from '../components/Navbar';
// import Footer from '../components/Footer';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, GithubAuthProvider } from "firebase/auth";
import { auth } from '../../utils/firebase';
import { useUserData } from "../../context/UserDataContext"; // Import the user data context
import { redirect } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import 'tailwindcss/tailwind.css';


export default function Login() {
  const { updateUser } = useUserData(); // Use the user data context
  const [user, setUser] = useState(null);

  const loader = async () => {
    if (!user) {
      redirect("/home"); // Redirect to login page
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const { email, password } = event.target.elements;
    try {
      await signInWithEmailAndPassword(auth, email.value, password.value);
      // User is logged in
      // You can redirect to homepage or dashboard here
      // redirect("/home");
      // Update user data in the context (if needed)
      updateUser(user);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    loader();
  }, []);
  

  const googleProvider = new GoogleAuthProvider();
  const handleLoginWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      // User is logged in
      // You can redirect to homepage or dashboard here

      // Access the user data after Google sign-in
      const userData = result.user;
      console.log("Google Sign-In User Data:", userData);

      // Set the user data in the component's state
      setUser(userData);

      // Update the user data in the context
      updateUser(userData);
    } catch (error) {
      alert(error);
    }
  };

  const githubProvider = new GithubAuthProvider();
  const handleLoginWithGithub = async () => {
    try {
      const result = await signInWithPopup(auth, githubProvider);
      // User is logged in
      // You can redirect to homepage or dashboard here

      // Access the user data after GitHub sign-in
      const userData = result.user;
    //   console.log("GitHub Sign-In User Data:", userData);

      // Set the user data in the component's state
      setUser(userData);

      // Update the user data in the context
      updateUser(userData);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-8 text-center text-2xl font-bold gilroy leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form className="space-y-6" action="#" method="POST" onSubmit={handleLogin}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 appearance-none ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-safevoy-green sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>

                  <div className="text-sm leading-6">
                    <a href="#" className="font-semibold gilroy text-safevoy-green">
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 appearance-none  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-safevoy-green sm:text-sm sm:leading-6"
                  />
                </div>
              </div>


              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-safevoy-green px-3 py-1.5 text-sm font-semibold gilroy leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-safevoy-green"
                >
                  Sign in
                </button>
              </div>
            </form>

            <div>
                <div className="relative mt-10">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900">Or continue with</span>
                  </div>
                </div>
  
                <div className="mt-6 grid grid-cols-2 gap-4">
                  <a
                    onClick={handleLoginWithGoogle}
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-white shadow px-3 py-1.5 text-black border cursor-pointer"
                  >
                    <svg className="h-5 w-5" viewBox="0 0 24 24">
                    <path fill="#4285F4" d="M23.653 12.413c0-.79-.07-1.54-.2-2.263H12.04v4.28h6.576a5.612 5.612 0 01-2.437 3.686v3.057h3.951a11.728 11.728 0 003.573-8.76z"/>
                    <path fill="#34A853" d="M12.04 24c3.292 0 6.046-1.1 8.057-2.99l-3.951-3.056a7.408 7.408 0 01-4.106 1.14c-3.124 0-5.76-2.12-6.696-4.96H1.957v3.11A11.985 11.985 0 0012.04 24z"/>
                    <path fill="#FBBC05" d="M5.343 14.62a7.345 7.345 0 01-.38-2.32c0-.79.14-1.56.38-2.29v-3.11H1.958a11.902 11.902 0 000 10.84l3.385-3.11z"/>
                    <path fill="#EA4335" d="M12.04 4.78c1.837 0 3.48.63 4.76 1.87l3.563-3.563C18.463 1.39 15.713 0 12.04 0A11.985 11.985 0 001.958 6.18l3.385 3.11A7.408 7.408 0 0112.04 4.78z"/>
                    </svg>

                    <span className="text-sm font-semibold gilroy leading-6">Google</span>
                  </a>
  
                  <a
                    onClick={handleLoginWithGithub}
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline cursor-pointer"
                  >
                    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-sm font-semibold gilroy leading-6">GitHub</span>
                  </a>
                </div>
              </div>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Don&apos;t have an account?{' '}
            <a href="#" className="font-medium leading-6 text-safevoy-green hover:text-safevoy-green">
              Sign up here
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
