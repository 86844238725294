import {Helmet} from "react-helmet";
import DashNavbar from '../../components/DashNavbar'
import SettingsContent from '../../components/Settings'

function SettingsPage() {
    return (
        <div className='lg:flex grow flex-col gap-5'>
            <SettingsContent/>
        </div>
    );
}

function Settings() {
    return (
        <div>
            <Helmet>
                <title>Safevoy | Settings</title>
            </Helmet>

            <div>
                <DashNavbar
                    PageName='Settings'
                    PageContent={SettingsPage}
                />
            </div>
        </div>
    );
}

export default Settings;
